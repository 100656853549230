import { init, captureException } from '@sentry/svelte';
import { consoleSandbox } from '@sentry/utils';
import type { HandleClientError } from '@sveltejs/kit';

init({
	dsn: 'https://baee605d202e4edcbe5d5786c5523696@o4504601070010368.ingest.us.sentry.io/4507886217330688',
	tracesSampleRate: 1.0,
});

export const handleError: HandleClientError = (input) => {
	if (input.status !== 404) {
		captureException(input.error, {
			mechanism: {
				type: 'sveltekit',
				handled: false,
			},
		});
	}
	consoleSandbox(() => {
		console.error(input.error);
	});
};
