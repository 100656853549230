import type { ParamMatcher } from '@sveltejs/kit';

const START_DATE = new Date('2024-08-31');

export const match: ParamMatcher = (param) => {
	const valid = /^\d{4}-\d{2}-\d{2}$/.test(param);
	if (!valid) return false;
	const date = new Date(param);
	return date >= START_DATE;
};
